<template>
  <b-container fluid="md" class="mt-1" style="max-width: 900px;">
    <b-row cols="2" cols-sm="3" cols-md="4" cols-lg="4">
      <b-col v-for="(item, idx) in gameList" :key="idx" class="px-3 my-3" @click="onGameClicked(item)">
        <b-card style="max-width: 20rem; box-shadow: 2px 3px 5px #222;" class="">
          <b-aspect>
            <b-card-img-lazy :src="item.icon" :alt="item.name" rounded></b-card-img-lazy>
          </b-aspect>
        </b-card>
        <div class="mt-3">{{ item.name }}</div>
      </b-col>
    </b-row>
    <infinite-loading :identifier="infiniteId" @infinite="getGameList"></infinite-loading>
  </b-container>
</template>

<script>
import gameApi from '../api/gameApi.js';

export default {
  name: "GameList",
  created() { },
  data() {
    return {
      gameList: [],
      page: 0,
      isLoading: false,
      pageSize: 20,
      infiniteId: +new Date(),
    };
  },
  props: {
    gameType: {
      type: Number,
      default: 0,
    },
    gameName: {
      type: String,
      default: '',
    },
    hot: {
      type: Number,
      default: -1,
    },
    recommend: {
      type: Number,
      default: -1,
    },
    excludeGameId: {
      type: Number,
      default: -1,
    },
  },
  watch: {
    gameType() {
      this.refresh();
    },
  },
  methods: {
    refresh() {
      this.isLoading = false;
      this.page = 0;
      this.gameList = [];
      this.infiniteId += 1;
    },
    getGameList($state) {
      if (!this.isLoading) {
        this.isLoading = true;

        console.log('getGameList: ', this.page, this.pageSize, this.gameType, this.gameName, this.hot, this.recommend);

        gameApi.getGameList(this.page, this.pageSize, this.gameType, this.gameName, this.hot, this.recommend).then((response) => {
          console.log('response', response);
          this.isLoading = false;

          if (response.status === 200 && response.data.code == 0) {
            if (response.data.data.items.length == 0) {
              $state.complete();
              return;
            }

            var shuffledgamelist = this.shuffleArray(response.data.data.items);

            this.gameList = this.gameList.concat(shuffledgamelist);

            //remove game from game list by game id
            if (this.excludeGameId > 0) {
              this.gameList = this.gameList.filter(item => item.id != this.excludeGameId);
            }

            console.log('gameList: ', this.gameList);
            this.page += 20;
            $state.loaded();
          } else {
            console.error('request gameApi.getGameList error', response);
            $state.complete();
          }
        });
      } else {
        $state.loaded();
      }
    },
    onGameClicked(item) {
      console.log('onGameClicked: ', item);
      this.$router.push({ name: 'game', params: { gamename: this.getGameNameWithDash(item.name), gameid: item.id } }).catch(err => { err });
    },
    getGameName(str) {
      if (!str) {
        return
      }
      return str.replace(/([a-z0-9])([A-Z0-9])/g, '$1 $2')
    },
    getGameNameWithDash(str) {
      // convert string with white space into array
      if (!str) {
        return
      }
      return str.replaceAll(' ', '-').toLowerCase();
    },
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    }
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}

.card-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* Optional: scale and crop the image to fill the element */
}
</style>